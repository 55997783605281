import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { GridSection, GridBox, FullWidthElement } from '../Grid';
import {
  sectionHeight,
  carouselTextMobileLayout,
  carouselItemLayoutSharp,
} from './layouts';
import { CarouselWrapper } from './ImageCarousel';
import { Heading2, Text } from '../../helper/Typography';
import { CarouselControls } from './CarouselControls';
import { colors } from '../../helper/Variables';
import { Picture } from '../Picture';
import { AssetAccessor } from '@lws/react-components';

interface ImageCarouselSharpProps {
  slideText: any;
  isMobile: boolean;
  className?: string;
  sliderImages: Array<AssetAccessor>;
  sliderImagesMobile: Array<AssetAccessor>;
}

interface ImageCarouselSharpState {
  slideIndex: number;
}

class ImageCarouselSharp extends Component<
  ImageCarouselSharpProps,
  ImageCarouselSharpState
> {
  constructor(props: ImageCarouselSharpProps) {
    super(props);
    this.state = {
      slideIndex: 0,
    };
  }
  render() {
    const {
      slideText,
      sliderImages,
      sliderImagesMobile,
      isMobile,
      className,
    } = this.props;
    const { slideIndex } = this.state;
    return (
      <CarouselWrapper className={className}>
        <CarouselControls
          slides={this.props.slideText.length}
          prevDisabled={slideIndex === 0}
          nextDisabled={slideIndex === slideText.length - 1}
          onNextClick={() =>
            this.setState({
              slideIndex: slideIndex + 1,
            })
          }
          onPrevClick={() =>
            this.setState({
              slideIndex: slideIndex - 1,
            })
          }
          active={slideIndex}
          dark={false}
        />
        <Carousel
          onChange={slideIndex => {
            this.setState({ slideIndex });
          }}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={slideIndex}
          swipeable
          emulateTouch
          showArrows={false}
          swipeScrollTolerance={3}
        >
          {slideText.map((slide: any, idx: number) => (
            <div key={`slide-${idx}`}>
              <GridSection gridRows={sectionHeight.carouselItemSharp}>
                {isMobile && (
                  <FullWidthElement>
                    <Picture
                      data={sliderImagesMobile[idx]}
                      alt={slide.image.alt}
                      title={slide.image.title}
                      asBackgroundImage={true}
                    />
                  </FullWidthElement>
                )}
                {!isMobile && (
                  <FullWidthElement>
                    <Picture
                      data={sliderImages[idx]}
                      alt={slide.image.alt}
                      title={slide.image.title}
                      asBackgroundImage={true}
                    />
                  </FullWidthElement>
                )}
                <GridBox
                  layout={carouselItemLayoutSharp}
                  style={{
                    alignContent: isMobile ? 'start' : 'end',
                  }}
                >
                  <Heading2
                    className={'showAbove-tablet1'}
                    style={{ color: colors.white }}
                  >
                    {slide.headline}
                  </Heading2>
                  <Text.p
                    className={'showAbove-tablet1'}
                    style={{ color: colors.white }}
                  >
                    {slide.text}
                  </Text.p>
                </GridBox>
              </GridSection>
              {isMobile && (
                <GridSection
                  gridRows={sectionHeight.carouselItemMobileSharp}
                  dark={true}
                >
                  <GridBox
                    layout={carouselTextMobileLayout}
                    translateTop={-0.5}
                  >
                    <Heading2>{slide.headline}</Heading2>
                    <Text.p style={{ marginTop: '20px' }}>{slide.text}</Text.p>
                  </GridBox>
                </GridSection>
              )}
            </div>
          ))}
        </Carousel>
      </CarouselWrapper>
    );
  }
}
export default ImageCarouselSharp;
