export const sectionHeight = {
  contentSection1: [6, 6, 6, 7, 5, 5, 5, 5, 5],
  contentSection2: [4, 4, 4, 5, 5, 4, 4, 4, 4],
};

export const contentSection1 = {
  text: {
    top:        [2, 2, 2, 2, 3, 3, 3, 3, 3],
    left:       [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth:   [3, 3, 3, 3, 2, 2, 2, 2, 2],
    }, 
  player: {
    top:        [4, 4, 4, 5, 3, 3, 3, 3, 3],
    left:       [1, 1, 1, 2, 5, 5, 6, 7, 8],
    boxWidth:   [3, 3, 4, 4, 4, 4, 4, 4, 4],
    },
  p13: {
    top:        [2, 2, 2, 1, 1, 1, 1, 1, 1],
    left:       [1, 1, 1, 1, 1, 1, 2, 3, 4],
    },
  p14: {
    top:        [2, 2, 2, 1, 1, 1, 1, 1, 1],
    left:       [1, 1, 1, 2, 2, 2, 3, 4, 5],
    },
  fish: {
    top:        [1, 1, 1, 4, 2, 2, 2, 2, 2],
    left:       [3, 3, 4, 6, 8, 8, 9, 10, 11],
    },
};

export const contentSection2 = {
  text: {
    top:        [1, 1, 2, 2, 2, 2, 2, 2, 2],
    left:       [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth:   [3, 3, 4, 4, 4, 4, 4, 4, 4],
    },  
  p16: {
    top:        [2, 2, 3, 4, 3, 3, 3, 3, 3],
    left:       [1, 1, 1, 6, 8, 8, 9, 10, 11],
    },
};
