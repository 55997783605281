export const eyeStates = [
  {
    id: '01_Start',
    duration: 1200,
    loop: false,
    amountOfFrames: 18,
    chainToState: 1,
  },
  {
    id: '02_Loop',
    duration: 1500,
    loop: true,
    amountOfFrames: 15,
  },
  {
    id: '03_Scroll',
    duration: 800,
    loop: false,
    amountOfFrames: 5,
  },
  {
    id: '04_End',
    duration: 1000,
    loop: false,
    amountOfFrames: 12,
    freezeAfter: true,
  },
];

export const eyeStatesMobile = [
  {
    id: '01_Start',
    duration: 1200,
    loop: false,
    amountOfFrames: 18,
    chainToState: 1,
  },
  {
    id: '02_Loop',
    duration: 1500,
    loop: false,
    amountOfFrames: 15,
    chainToState: 2,
  },
  {
    id: '03_Scroll',
    duration: 800,
    loop: false,
    amountOfFrames: 5,
  },
];
