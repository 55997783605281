import * as React from 'react';
import {
  SimpleImage,
  PlayerWrapper,
  OverlayWrapper,
  PlayButtonWrapper,
  PlayButton,
} from '../../helper/StyledHelper';
import { Component } from 'react';
import Player from '@vimeo/player';
import { Picture } from '../Picture';
import { AssetAccessor } from '@lws/react-components';

interface HDAInlineVideoPlayerProps {
  videoId: string;
  posterImg: AssetAccessor;
  alt: string;
  title: string;
}

interface HDAInlineVideoPlayerState {
  showPlayer: boolean;
}
export class HDAInlineVideoPlayer extends Component<
  HDAInlineVideoPlayerProps,
  HDAInlineVideoPlayerState
> {
  playerRef: React.Ref<any>;
  player: Player;
  constructor(props: HDAInlineVideoPlayerProps) {
    super(props);
    this.state = { showPlayer: false };
    this.playerRef = React.createRef();
    this.triggerPlayer = this.triggerPlayer.bind(this);
  }

  setVideoWidthByWindowSize() {
    if (typeof window !== 'undefined') {
      if (window.innerWidth >= 1280) {
        return 640;
      }
      if (window.innerWidth >= 768) {
        return 480;
      }
      if (window.innerWidth >= 480) {
        return 448;
      }
      if (window.innerWidth >= 321) {
        return 336;
      }
      return 300;
    } else return;
  }

  componentDidMount = () => {
    try {
      this.player = new Player(this.playerRef && this.playerRef.current, {
        id: this.props.videoId,
        width: this.setVideoWidthByWindowSize(),
      });
      this.player.on('ended', () => {
        this.setState({
          showPlayer: !this.state.showPlayer,
        });
      });
    } catch (error) {
      console.error(`Vimeo-Error: ${error}`);
    }
  };

  triggerPlayer() {
    try {
      this.setState({ showPlayer: true }, () =>
        this.player.on('loaded', () => {
          this.player
            .play()
            .then()
            .catch((error: Error) => {
              console.error(`Vimeo-Error: ${error}`);
            });
        })
      );
    } catch (error) {
      console.error;
    }
  }

  render() {
    const { posterImg, alt, title } = this.props;
    return (
      <>
        <OverlayWrapper>
          <PlayerWrapper
            ref={this.playerRef}
            showPlayer={this.state.showPlayer}
          ></PlayerWrapper>
          <PlayerWrapper showPlayer={!this.state.showPlayer}>
            <PlayButtonWrapper>
              <Picture
                data={posterImg}
                alt={alt}
                title={title}
                asBackgroundImage={true}
              >
                <PlayButton>
                  <SimpleImage
                    onClick={this.triggerPlayer}
                    src={'/assets/icons/play-button.svg'}
                  />
                </PlayButton>
              </Picture>
            </PlayButtonWrapper>
          </PlayerWrapper>
        </OverlayWrapper>
      </>
    );
  }
}
