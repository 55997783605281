import * as React from 'react';
import { graphql } from 'gatsby';
import PageEnhancer, { EntryPageProps } from '../../../components/PageEnhancer';
import {
  Anchor,
  Sprite,
  PageLayout,
  Header,
  DarkIntroSection,
} from '../../../components';
import {
  FullWidthElement,
  GridSection,
  GridBox,
} from '../../../components/Grid';
import { sectionHeight, introStageImage } from './layout';
import {
  SeoDataComponent,
  GraphQLContentAccessor,
} from '@lws/react-components';
import { SimpleImage } from '../../../helper/StyledHelper';
import { Challenge, Ideation, Results, Jobs } from './sections';
import { flagStates } from './animations';
import { mapMetaObject } from '../../../helper/helperServices';
import { Picture } from '../../../components/Picture';
import { AssetAccessor } from '@lws/react-components';

class HDA extends PageEnhancer {
  constructor(props: EntryPageProps) {
    super(props);
    this.state = {
      flag: undefined,
      darkTheme: true,
      darkSections: [
        'intro',
        props.pageContext.texts.deepLinks.ideation.hash,
        props.pageContext.texts.deepLinks.jobs.hash,
      ],
    };
  }

  componentDidMount = () => {
    this.callSuperComponentDidMountMethods();
    this.setInitialAnimationsState(['flag']);
  };

  render() {
    const { data } = this.props;
    const context = this.props.pageContext;
    const { locale, texts, generalTexts } = context;
    const { darkTheme, isScreenDesktop, activeBreakpoint } = this.state;
    const { pathname } = this.props.location;

    // Transform only images GraphQL response into Accesssor data
    const accessorData = new GraphQLContentAccessor(this.props.data);

    const images: { [x: string]: AssetAccessor } = {
      introStageImage: accessorData.file('introStageImage'),
      introStageImageMobile: accessorData.file('introStageImageMobile'),
    };

    const isMobile =
      typeof activeBreakpoint !== 'undefined' &&
      activeBreakpoint.name.includes('mobile');

    return (
      <PageLayout
        userLanguage={locale}
        generalText={generalTexts}
        darkTheme={darkTheme}
      >
        <main className={darkTheme ? 'App Dark' : 'App Light'}>
          <Header
            pathname={pathname}
            darkTheme={darkTheme}
            generalTexts={generalTexts}
            lang={locale}
            navigationItems={texts.deepLinks}
          />
          <SeoDataComponent
            data={
              mapMetaObject({
                ...texts.meta,
                canonical:
                  data.site.siteMetadata.siteUrl + this.props.location.pathname,
                hostname: data.site.siteMetadata.siteUrl,
              }).data
            }
          />
          <React.Fragment>
            <Anchor id="intro" first={true} />
            <DarkIntroSection texts={texts} />
          </React.Fragment>
          <GridSection
            gridRows={sectionHeight.introStageImage}
            dark={darkTheme}
            translateTop={[-3, -2, -2, -2]}
          >
            <GridBox layout={introStageImage.introStageImage}>
              {!isMobile && (
                <FullWidthElement>
                  <Picture
                    data={images.introStageImage}
                    alt={texts.intro.image.alt}
                    title={texts.intro.image.title}
                    asBackgroundImage={true}
                  />
                </FullWidthElement>
              )}
              {isMobile && (
                <FullWidthElement>
                  <Picture
                    data={images.introStageImageMobile}
                    alt={texts.intro.image.alt}
                    title={texts.intro.image.title}
                    asBackgroundImage={true}
                  />
                </FullWidthElement>
              )}
            </GridBox>
            <GridBox layout={introStageImage.pageLogo} translateTop={-1}>
              <SimpleImage
                src={'/assets/caseStudies/h_da/pattern/page-logo.svg'}
              />
            </GridBox>
            {typeof this.state.flag !== 'undefined' && (
              <Sprite
                id={'flagStart'}
                layout={introStageImage.flag}
                name={'flag'}
                startId={'flagStart'}
                endId={'flagEnd'}
                animationState={this.state.flag}
                setAnimationState={this.setAnimationState}
                isActive={true}
                isSticky={isScreenDesktop}
                translateTop={0}
                originalAnimState={0}
                onRelease={() => {
                  this.setAnimationState('flag', 1);
                }}
                onScroll={() => this.setAnimationState('flag', 0)}
                animationsDefinitions={flagStates}
              />
            )}

            <GridBox
              id={'flagEnd'}
              translateTop={[2, 2, 1.6, 2]}
              layout={introStageImage.flagEnd}
            />
          </GridSection>
          {/* ======== ======== Challenge Section ======== ======== */}
          <Anchor id={texts.deepLinks.challenge.hash} />
          <Challenge
            texts={texts}
            darkTheme={darkTheme}
            accessorData={accessorData}
            locale={locale}
            pathname={this.props.location.pathname}
            isScreenDesktop={isScreenDesktop}
            isMobile={isMobile}
          />
          {/* ======== ======== Ideation Section ======== ======== */}
          <Anchor id={texts.deepLinks.ideation.hash} />
          <Ideation
            texts={texts}
            labelTexts={context.generalTexts.caseStudies}
            darkTheme={darkTheme}
            isMobile={isMobile}
            accessorData={accessorData}
          />
          {/* ======== ======== Results Section ======== ======== */}
          <Anchor id={texts.deepLinks.results.hash} />
          <Results
            texts={texts}
            darkTheme={darkTheme}
            accessorData={accessorData}
            isScreenDesktop={isScreenDesktop}
          />
          {/* ======== ======== Jobs Section ======== ======== */}
          <Anchor id={texts.deepLinks.jobs.hash} />
          <Jobs
            labelTexts={context.generalTexts}
            texts={texts}
            darkTheme={darkTheme}
            accessorData={accessorData}
            isScreenDesktop={isScreenDesktop}
            locale={locale}
          />
        </main>
      </PageLayout>
    );
  }
}

export const HDAQuery = graphql`
  query SharpenLocalImagesHDA {
    introStageImage: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/hochschulkooperation-neugelb-h-da.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    introStageImageMobile: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/hochschulkooperation-neugelb-h-da-mobile.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    ...ChallengeImagesFragment
    ...IdeationImagesFragment
    ...ResultsImagesFragment
    ...JobsImagesFragment
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default HDA;
