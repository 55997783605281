import React from 'react';
import styled from 'styled-components';
import { GridSection, GridBox } from '../../../../../components/Grid';
import {
  sectionHeight,
  contentSection1,
  contentSection2,
  contentSection3,
  contentSection4,
} from './layout';
import { Heading1, Heading2, Text } from '../../../../../helper/Typography';
import { graphql } from 'gatsby';
import { SimpleImage } from '../../../../../helper/StyledHelper';
import Sprite from '../../../../../components/Sprite/Sprite';
import { officesStates } from './animations';
import ImageCarouselSharp from '../../../../../components/ImageCarousel/ImageCarouselSharp';
import { LineBreakRenderer, PageEnhancer } from '../../../../../components';
import SocialIcons from '../../../../../components/SocialIcons/SocialIcons';
import { GraphQLContentAccessor, AssetAccessor } from '@lws/react-components';
import { Picture } from '../../../../../components/Picture';

interface ChallengeProps {
  darkTheme: boolean;
  texts: any;
  accessorData: GraphQLContentAccessor<any>;
  locale: string;
  isScreenDesktop: boolean;
  isMobile: true;
  pathname: string;
}

class Challenge extends PageEnhancer {
  constructor(props: ChallengeProps) {
    super(props);
    this.state = {
      offices: undefined,
    };
  }

  componentDidMount() {
    this.setInitialAnimationsState(['offices']);
  }

  render() {
    const {
      texts,
      accessorData,
      locale,
      isScreenDesktop,
      isMobile,
      darkTheme,
      pathname,
    } = this.props;
    const { offices } = this.state;

    const sliderImagesMobile: Array<AssetAccessor> = [
      accessorData.file('sliderImage1Mobile'),
      accessorData.file('sliderImage2Mobile'),
      accessorData.file('sliderImage3Mobile'),
      accessorData.file('sliderImage4Mobile'),
      accessorData.file('sliderImage5Mobile'),
      accessorData.file('sliderImage6Mobile'),
      accessorData.file('sliderImage7Mobile'),
    ];
    const sliderImages: Array<AssetAccessor> = [
      accessorData.file('sliderImage1'),
      accessorData.file('sliderImage2'),
      accessorData.file('sliderImage3'),
      accessorData.file('sliderImage4'),
      accessorData.file('sliderImage5'),
      accessorData.file('sliderImage6'),
      accessorData.file('sliderImage7'),
    ];

    return (
      <React.Fragment>
        <GridSection gridRows={sectionHeight.contentSection1}>
          <GridBox
            layout={contentSection1.shareLinks}
            style={{ alignContent: 'center' }}
          >
            <FlexBox>
              <SocialIcons
                isSharing={true}
                locale={locale}
                dark={darkTheme}
                pathname={pathname}
              />
            </FlexBox>
          </GridBox>
          <GridBox
            layout={contentSection1.text}
            translateTop={[0.3, 0.3, 0, 0]}
          >
            <Heading1 style={{ whiteSpace: 'nowrap' }}>
              <LineBreakRenderer
                text={texts.challenge.contentSection1.headline}
              />
            </Heading1>
            {texts.challenge.contentSection1.text.map(
              (p: string, idx: number) => (
                <Text.p key={`contentSection1-text-${idx}`}>{p}</Text.p>
              )
            )}
          </GridBox>
        </GridSection>
        <GridSection gridRows={sectionHeight.contentSection2}>
          <GridBox
            layout={contentSection2.image2}
            translateTop={[-0.5, -0.5, 0, 0]}
          >
            <Picture
              data={accessorData.file('challengeImage2')}
              alt={texts.challenge.contentSection1.image02.alt}
              title={texts.challenge.contentSection1.image02.title}
              asBackgroundImage={true}
            />
          </GridBox>
          <GridBox
            layout={contentSection2.copyright}
            translateTop={[-0.5, -0.5, 0, 0]}
            style={{
              textAlign: isScreenDesktop ? 'right' : 'left',
            }}
          >
            <Text.pGrey>
              {locale === 'de' ? 'Foto: ' : 'Photo: '} Commerzbank AG
            </Text.pGrey>
          </GridBox>

          <GridBox
            layout={contentSection2.image1}
            translateTop={[-0.5, -0.5, 0, 0]}
          >
            <Picture
              data={accessorData.file('challengeImage1')}
              asBackgroundImage={true}
              alt={texts.challenge.contentSection1.image01.alt}
              title={texts.challenge.contentSection1.image01.title}
            />
          </GridBox>
          <GridBox
            layout={contentSection2.p01}
            translateTop={[-0.5, -0.5, 0, 0]}
          >
            <SimpleImage
              src={'/assets/caseStudies/h_da/pattern/pattern-01.svg'}
            />
          </GridBox>
          <GridBox layout={contentSection2.p02} className="showAbove-tablet1">
            <SimpleImage
              src={'/assets/caseStudies/h_da/pattern/pattern-02.svg'}
            />
          </GridBox>
          <GridBox
            layout={contentSection2.p03}
            translateTop={[-0.5, 0.5, 0, 0]}
            className="showAbove-mobile3"
          >
            <SimpleImage
              src={'/assets/caseStudies/h_da/pattern/pattern-03.svg'}
            />
          </GridBox>
        </GridSection>
        <GridSection gridRows={sectionHeight.contentSection3}>
          <GridBox
            layout={contentSection3.headline}
            className="showAbove-tablet1"
          >
            <Heading2>
              <LineBreakRenderer
                text={texts.challenge.contentSection3.headline}
              />
            </Heading2>
          </GridBox>
          <GridBox layout={contentSection3.text} className="showAbove-tablet1">
            {texts.challenge.contentSection3.text.map(
              (p: string, idx: number) => (
                <Text.p key={`contentSection3-text-${idx}`}>{p}</Text.p>
              )
            )}
          </GridBox>
          <GridBox layout={contentSection3.text} className="hideAbove-tablet1">
            <Heading2>
              <LineBreakRenderer
                text={texts.challenge.contentSection3.headline}
              />
            </Heading2>
            {texts.challenge.contentSection3.text.map(
              (p: string, idx: number) => (
                <Text.p key={`contentSection3-text-${idx}`}>{p}</Text.p>
              )
            )}
          </GridBox>
          {typeof offices !== 'undefined' && (
            <Sprite
              layout={contentSection3.p04}
              name={'offices'}
              animationState={offices}
              setAnimationState={this.setAnimationState}
              isSticky={false}
              isActive={true}
              animationsDefinitions={officesStates}
              translateTop={[0.5, 0.5, 0.5, 0]}
            />
          )}
          <GridBox
            layout={contentSection3.p05}
            translateTop={0.5}
            className="showAbove-tablet1"
          >
            <SimpleImage
              src={'/assets/caseStudies/h_da/pattern/pattern-05.svg'}
            />
          </GridBox>
        </GridSection>
        <ImageCarouselSharp
          className={!isScreenDesktop && 'Dark'}
          sliderImages={sliderImages}
          sliderImagesMobile={sliderImagesMobile}
          slideText={texts.challenge.sliderSection}
          isMobile={!isScreenDesktop}
        ></ImageCarouselSharp>
        <GridSection gridRows={sectionHeight.contentSection4}>
          <GridBox layout={contentSection4.text}>
            <Heading2>
              <LineBreakRenderer
                text={texts.challenge.contentSection4.headline}
              />
            </Heading2>
            {texts.challenge.contentSection4.text.map(
              (p: string, idx: number) => (
                <Text.p key={`contentSection4-text-${idx}`}>{p}</Text.p>
              )
            )}
          </GridBox>
        </GridSection>
      </React.Fragment>
    );
  }
}

export default Challenge;

export const ChallengeImagesFragment = graphql`
  fragment ChallengeImagesFragment on Query {
    challengeImage1: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/01_Challenge/sparen-vs-innovatives-banking.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    challengeImage2: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/01_Challenge/case-study-banking.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    sliderImage1: file(
      relativePath: { eq: "assets/caseStudies/h_da/01_Challenge/slider-01.jpg" }
    ) {
      ...FileAssetFragment
    }
    sliderImage2: file(
      relativePath: { eq: "assets/caseStudies/h_da/01_Challenge/slider-02.jpg" }
    ) {
      ...FileAssetFragment
    }
    sliderImage3: file(
      relativePath: { eq: "assets/caseStudies/h_da/01_Challenge/slider-03.jpg" }
    ) {
      ...FileAssetFragment
    }
    sliderImage4: file(
      relativePath: { eq: "assets/caseStudies/h_da/01_Challenge/slider-04.jpg" }
    ) {
      ...FileAssetFragment
    }
    sliderImage5: file(
      relativePath: { eq: "assets/caseStudies/h_da/01_Challenge/slider-05.jpg" }
    ) {
      ...FileAssetFragment
    }
    sliderImage6: file(
      relativePath: { eq: "assets/caseStudies/h_da/01_Challenge/slider-06.jpg" }
    ) {
      ...FileAssetFragment
    }
    sliderImage7: file(
      relativePath: { eq: "assets/caseStudies/h_da/01_Challenge/slider-07.jpg" }
    ) {
      ...FileAssetFragment
    }
    sliderImage1Mobile: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/01_Challenge/slider-01-mobile.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    sliderImage2Mobile: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/01_Challenge/slider-02-mobile.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    sliderImage3Mobile: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/01_Challenge/slider-03-mobile.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    sliderImage4Mobile: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/01_Challenge/slider-04-mobile.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    sliderImage5Mobile: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/01_Challenge/slider-05-mobile.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    sliderImage6Mobile: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/01_Challenge/slider-06-mobile.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    sliderImage7Mobile: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/01_Challenge/slider-07-mobile.jpg"
      }
    ) {
      ...FileAssetFragment
    }
  }
`;

const FlexBox = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
`;
