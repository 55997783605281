// BPS:
// 1 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

export const sectionHeight = {
  contentSection1:  [5, 4, 4, 6, 6, 5, 5, 5, 5],
  spacerSection:  [1, 1, 1, 1, 1, 1, 1, 1, 1],
};

export const contentSection1 = {
  text: {
    top: [2, 2, 2, 2, 2, 3, 3, 3, 3],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 5, 5, 4, 4, 4, 4],
  },
  p05: {
    top: [1, 1, 1, 1, 1, 3, 3, 3, 3],
    left: [1, 1, 1, 1, 1, 1, 2, 3, 4],
  },
  p06: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [2, 2, 2, 2, 2, 7, 8, 9, 10],
  },
  p07: {
    top: [3, 3, 4, 1, 1, 1, 1, 1, 1],
    left: [3, 3, 3, 6, 8, 8, 9, 10, 11],
  },
};
