// BPS:
// 1 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

export const sectionHeight = {
  contentSection1: [8, 7, 6, 8, 8, 6, 6, 6, 6],
  contentSection2: [5, 5, 7, 6, 5, 5, 5, 5, 5],
  contentSection3: [6, 5, 4, 5, 5, 4, 4, 4, 4],
  contentSection4: [9, 7, 6, 7, 7, 4, 4, 4, 4],
};

export const contentSection1 = {
  text: {
    top:  [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 5, 5, 4, 4, 4, 4],
  },
  shareLinks: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 1, 1, 1, 1, 2, 3],
  },
};

export const contentSection2 = {
  image1: {
    top: [2, 2, 2, 1, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth: [2, 2, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [2, 2, 3, 3, 3, 3, 3, 3, 3],
  },
  image2: {
    top: [4, 4, 5, 4, 1, 1, 1, 1, 1],
    left: [2, 2, 2, 3, 5, 5, 6, 7, 8],
    boxWidth: [2, 2, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [2, 2, 3, 2, 2, 2, 2, 2, 2],
  },
  copyright: {
    //bps     [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:      [6, 6, 8, 6, 3, 3, 3, 3, 3],
    left:     [2, 2, 2, 3, 4, 6, 6, 7, 8],
    boxWidth: [2, 2, 3, 3, 4, 2, 3, 3, 3],
  },
  p01: {
    top: [4, 4, 5, 4, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 1, 1, 1, 2, 3, 4],
  },
  p02: {
    top: [3, 3, 3, 3, 1, 1, 1, 1, 1],
    left: [2, 2, 2, 2, 4, 4, 5, 6, 7],
  },
  p03: {
    top: [3, 3, 4, 2, 2, 2, 2, 2, 2],
    left: [3, 3, 3, 5, 8, 8, 9, 10, 11],
  },
};

export const contentSection3 = {
  headline: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth: [3, 3, 4, 4, 3, 2, 2, 2, 2],
  },
  text: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 2, 5, 5, 6, 7, 8],
    boxWidth: [3, 3, 4, 4, 3, 3, 3, 3, 3],
  },
  p04: {
    top:    [6, 5, 4, 5, 5, 4, 4, 4, 4],
    left:   [1, 1, 1, 2, 3, 3, 4, 5, 6],
  },
  p05: {
    top: [3, 3, 3, 4, 5, 4, 4, 4, 4],
    left: [2, 2, 2, 2, 4, 4, 5, 6, 7],
  },
};

export const contentSection4 = {
  text: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
  },
};
