import React from 'react';
import { graphql } from 'gatsby';
import { GridSection, GridBox } from '../../../../../components/Grid';
import { sectionHeight, contentSection1 } from './layout';
import { Heading1 } from '../../../../../helper/Typography';
import { LineBreakRenderer, HDAVideoPlayer } from '../../../../../components';
import { SimpleImage } from '../../../../../helper/StyledHelper';
import baufiAnimation from './baufi-lottie.json';
import baufiAnimationMobile from './baufiMobile-lottie.json';
import seamlessAnimation from './seamless-lottie.json';
import seamlessAnimationMobile from './seamlessMobile-lottie.json';
import smamoAnimation from './smamo-lottie.json';
import smamoAnimationMobile from './smamoMobile-lottie.json';
import { GraphQLContentAccessor, AssetAccessor } from '@lws/react-components';

interface IdeationProps {
  accessorData: GraphQLContentAccessor<any>;
  darkTheme: boolean;
  isMobile: boolean;
  texts: any;
  labelTexts: any;
  scrollTop?: number;
}
const Ideation = ({
  texts,
  darkTheme,
  labelTexts,
  scrollTop,
  isMobile,
  accessorData,
}: IdeationProps) => {
  const animations = {
    0: isMobile ? seamlessAnimationMobile : seamlessAnimation,
    1: isMobile ? seamlessAnimationMobile : seamlessAnimation,
    2: isMobile ? smamoAnimationMobile : smamoAnimation,
  };
  // Fallback images for the Lottie animations
  const images: { [x: string]: AssetAccessor } = {
    0: accessorData.file('projectImage1'),
    1: accessorData.file('projectImage2'),
    2: accessorData.file('projectImage3'),
  };
  return (
    <React.Fragment>
      <GridSection
        gridRows={sectionHeight.contentSection1}
        dark={darkTheme}
        className="Dark"
      >
        <GridBox
          layout={contentSection1.text}
          translateTop={[0.5, 0.5, 0.5, 0]}
        >
          <Heading1>
            <LineBreakRenderer text={texts.ideation.contentSection1.headline} />
          </Heading1>
        </GridBox>
        <GridBox layout={contentSection1.p05}>
          <SimpleImage
            src={'/assets/caseStudies/h_da/pattern/pattern-06.svg'}
          />
        </GridBox>
        <GridBox layout={contentSection1.p06}>
          <SimpleImage
            src={'/assets/caseStudies/h_da/pattern/pattern-07.svg'}
          />
        </GridBox>
        <GridBox layout={contentSection1.p07} className={'showAbove-tablet1'}>
          <SimpleImage
            src={'/assets/caseStudies/h_da/pattern/pattern-08.svg'}
          />
        </GridBox>
      </GridSection>
      {texts.ideation.videos.map((text: any, idx: number) => (
        <HDAVideoPlayer
          key={`video-${idx}`}
          animation={animations[idx]}
          text={text}
          darkTheme={true}
          labelTexts={labelTexts}
          scrollTop={scrollTop || 0}
          image={images[idx]}
        />
      ))}
      <GridSection gridRows={sectionHeight.spacerSection} dark={darkTheme} />
    </React.Fragment>
  );
};

export const IdeationImagesFragment = graphql`
  fragment IdeationImagesFragment on Query {
    projectImage1: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/02_Ideation/Baufi_desktop.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    projectImage2: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/02_Ideation/moneyid-desktop.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    projectImage3: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/02_Ideation/smamo-desktop.jpg"
      }
    ) {
      ...FileAssetFragment
    }
  }
`;

export default Ideation;
