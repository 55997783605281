import React from 'react';
import { Link, graphql } from 'gatsby';
import { GridSection, GridBox } from '../../../../../components/Grid';
import PageEnhancer from '../../../../../components/PageEnhancer';
import { sectionHeight, contentSection1, contentSection2 } from './layouts';
import { SimpleImage, Button } from '../../../../../helper/StyledHelper';
import { Heading2, Text } from '../../../../../helper/Typography';
import { fishStates } from './animations';
import Sprite from '../../../../../components/Sprite/Sprite';
import { HDAInlineVideoPlayer } from '../../../../../components/HDAInlineVideoPlayer/HDAInlineVideoPlayer';
import { getUrlFromId } from '../../../../../helper/helperServices';
import { LineBreakRenderer } from '../../../../../components';
import { GraphQLContentAccessor } from '@lws/react-components';

interface JobsProps {
  darkTheme: boolean;
  texts: any;
  accessorData: GraphQLContentAccessor<any>;
  isScreenDesktop: boolean;
  labelTexts: any;
  locale: string;
}

class Jobs extends PageEnhancer {
  constructor(props: JobsProps) {
    super(props);
    this.state = {
      fish: undefined,
    };
  }

  componentDidMount() {
    this.setInitialAnimationsState(['fish']);
  }

  render() {
    const { darkTheme, texts, labelTexts, locale, accessorData } = this.props;

    return (
      <React.Fragment>
        <GridSection gridRows={sectionHeight.contentSection1}>
          <GridBox layout={contentSection1.text} translateTop={[0, 0, 0.5, 0]}>
            <Heading2>
              <LineBreakRenderer text={texts.jobs.contentSection1.headline} />
            </Heading2>
            <Text.p>{texts.jobs.contentSection1.hashtag}</Text.p>
          </GridBox>
          <GridBox layout={contentSection1.player}>
            <HDAInlineVideoPlayer
              videoId="363783964"
              posterImg={accessorData.file('posterImg')}
              alt={texts.jobs.contentSection1.image.alt}
              title={texts.jobs.contentSection1.image.title}
            />
          </GridBox>
          <GridBox
            className={'showAbove-mobile3'}
            layout={contentSection1.p13}
            translateTop={[0, -0.5, -0.5, -0.5]}
          >
            <SimpleImage
              src={'/assets/caseStudies/h_da/pattern/pattern-13.svg'}
            />
          </GridBox>
          <GridBox layout={contentSection1.p14} className={'showAbove-mobile3'}>
            <SimpleImage
              src={'/assets/caseStudies/h_da/pattern/pattern-14.svg'}
            />
          </GridBox>
          {typeof this.state.fish !== 'undefined' && (
            <Sprite
              layout={contentSection1.fish}
              name={'fish'}
              animationState={this.state.fish}
              setAnimationState={this.setAnimationState}
              isSticky={false}
              isActive={true}
              animationsDefinitions={fishStates}
              translateTop={[-0.5, -0.5, 0, 0]}
            />
          )}
        </GridSection>
        <GridSection gridRows={sectionHeight.contentSection2}>
          <GridBox layout={contentSection2.text}>
            <Heading2>
              <LineBreakRenderer text={texts.jobs.contentSection2.headline} />
            </Heading2>
            <Text.p>{texts.jobs.contentSection2.text}</Text.p>
            <Link to={getUrlFromId(locale, 'jobs')}>
              <Button darkTheme={darkTheme}>{labelTexts.checkJobs}</Button>
            </Link>
          </GridBox>
          <GridBox layout={contentSection2.p16} className={'showAbove-mobile3'}>
            <SimpleImage
              src={'/assets/caseStudies/h_da/pattern/pattern-16.svg'}
            />
          </GridBox>
        </GridSection>
      </React.Fragment>
    );
  }
}

export default Jobs;

export const JobsImagesFragment = graphql`
  fragment JobsImagesFragment on Query {
    posterImg: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/04_Jobs/porto-neugelb-hda.jpg"
      }
    ) {
      ...FileAssetFragment
    }
  }
`;
