// BPS:
// 1 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

export const sectionHeight = {
  //bps:        [1, 2, 3, 4, 5, 6, 7, 8, 9]
  introStageImage: [6, 6, 6, 4, 4, 4, 4, 4, 4],
};

export const introStageImage = {
  introStageImage: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 1, 1, 1, 1, 2, 2],
    boxWidth: [3, 3, 4, 8, 8, 8, 10, 10, 12],
    boxHeight: [3, 3, 3, 3, 3, 3, 3, 4, 4],
  },
  pageLogo: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [3, 3, 4, 6, 8, 8, 9, 11, 12],
  },
  flag: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [6, 6, 6, 1, 1, 1, 1, 1, 1],
    left: [3, 3, 4, 5, 7, 7, 8, 10, 11],
  },
  flagEnd: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    left: [1, 1, 1, 6, 7, 7, 8, 9, 10],
  },
};
