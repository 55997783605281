export const flagStates = [
  {
    id: '01_loop',
    duration: 900,
    loop: true,
    amountOfFrames: 19,
  },
  {
    id: '01_loop',
    duration: 900,
    loop: true,
    amountOfFrames: 19,
    freezeAfter: true,
  },
];
