
export const sectionHeight = {
  contentSection1:  [10, 9, 10, 11, 6, 5, 5, 5, 5],
  contentSection2:  [9, 8, 9, 8, 6, 6, 6, 6, 6],
};

export const contentSection1 = {
    text: {
        top:        [3, 3, 3, 3, 3, 2, 2, 2, 2],
        left:       [1, 1, 1, 2, 2, 2, 3, 4, 5],
        boxWidth:   [3, 3, 4, 4, 4, 3, 3, 3, 3],
    },
    image: {
        top:        [6, 6, 6, 7, 2, 2, 2, 2, 2],
        left:       [1, 1, 1, 2, 7, 7, 8, 9, 10],
         boxWidth:   [3, 3, 4, 4, 2, 2, 2, 2, 2],
        boxHeight:  [3, 3, 4, 4, 3, 3, 3, 3, 3],
    },
    p10: {
        top:        [1, 1, 1, 1, 1, 1, 1, 1, 1],
        left:       [2, 2, 2, 4, 6, 6, 7, 8, 9]
    },
    eye: {
       top:         [1, 1, 1, 1, 1, 1, 1, 1, 1],
        left:       [3, 3, 4, 5, 7, 7, 8, 9, 10]
    },
    eyeEnd: {
        top:        [1, 1, 1, 1, 6, 5, 5, 5, 5],
        left:       [3, 3, 4, 5, 7, 7, 8, 9, 10]
    },
    p12: {
        top:        [1, 1, 1, 2, 2, 1, 1, 1, 1],
        left:       [1, 1, 1, 1, 1, 1, 2, 3, 4],
    },
}

export const contentSection2 = {
    headline: {
        top:        [1, 1, 1, 1, 1, 1, 1, 1, 1],
        left:       [1, 1, 1, 2, 2, 2, 3, 4, 5],
        boxWidth:   [3, 3, 4, 3, 3, 3, 3, 3, 3]
    },
    image: {
        top:        [5, 4, 4, 2, 2, 2, 2, 2, 2],
        left:       [1, 1, 1, 2, 2, 2, 3, 4, 5],
        boxWidth:   [3, 3, 4, 4, 4, 4, 4, 4, 4],
        boxHeight:  [3, 3, 4, 3, 3, 3, 3, 3, 3]
    },
    text: {
        top:        [2, 2, 2, 6, 3, 3, 3, 3, 3],
        left:       [1, 1, 1, 2, 7, 7, 8, 9, 10],
        boxWidth:   [3, 3, 4, 4, 2, 2, 2, 2, 2]
    },
}