import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PageEnhancer from '../../../../../components/PageEnhancer';
import { GridSection, GridBox } from '../../../../../components/Grid';
import { Sprite } from '../../../../../components';
import { sectionHeight, contentSection1, contentSection2 } from './layouts';
import { SimpleImage } from '../../../../../helper/StyledHelper';
import { Heading2, Text } from '../../../../../helper/Typography';
import { eyeStates } from './animations';
import { LineBreakRenderer } from '../../../../../components';
import { GraphQLContentAccessor } from '@lws/react-components';
import { Picture } from '../../../../../components/Picture';

interface ResultsProps {
  darkTheme: boolean;
  texts: any;
  accessorData: GraphQLContentAccessor<any>;
  isScreenDesktop: boolean;
}

class Results extends PageEnhancer {
  constructor(props: ResultsProps) {
    super(props);
    this.state = {
      eye: undefined,
    };
  }

  componentDidMount() {
    this.setInitialAnimationsState(['eye']);
  }

  render() {
    const { darkTheme, texts, accessorData, isScreenDesktop } = this.props;
    return (
      <React.Fragment>
        <GridSection gridRows={sectionHeight.contentSection1} dark={darkTheme}>
          <GridBox layout={contentSection1.text} translateTop={[0, 0, 0, 0.5]}>
            <Heading2>
              <LineBreakRenderer
                text={texts.results.contentSection1.headline}
              />
            </Heading2>
            <Text.p>{texts.results.contentSection1.text}</Text.p>
            <FlexBox>
              <Text.p
                className={'showAbove-mobile3'}
                style={{
                  marginRight: '50px',
                }}
              >
                <a
                  href={texts.results.contentSection1.url1}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {texts.results.contentSection1.linkName1}
                </a>
              </Text.p>
              <Text.p className={'showAbove-mobile3'}>
                <a
                  href={texts.results.contentSection1.url2}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {texts.results.contentSection1.linkName2}
                </a>
              </Text.p>
            </FlexBox>
          </GridBox>
          <GridBox
            layout={contentSection1.image}
            translateTop={[0.5, 0, 0, 0]}
            className={'centerContent'}
          >
            <Picture
              data={accessorData.file('pageCover')}
              alt={texts.results.contentSection2.image.alt}
              title={texts.results.contentSection2.image.title}
            />
          </GridBox>
          <GridBox className={'showAbove-mobile3'} layout={contentSection1.p10}>
            <SimpleImage src="/assets/caseStudies/h_da/pattern/pattern-10.svg" />
          </GridBox>
          {/*           <GridBox layout={contentSection1.p12} translateTop={[0.5, 0.5, 0, 0]}>
            <SimpleImage src="/assets/caseStudies/h_da/pattern/pattern-12.svg" />
          </GridBox> */}
          {typeof this.state.eye !== 'undefined' && (
            <Sprite
              id={'eyeStart'}
              layout={contentSection1.eye}
              name={'eye'}
              startId={'eyeStart'}
              endId={'eyeEnd'}
              animationState={this.state.eye}
              setAnimationState={this.setAnimationState}
              isSticky={isScreenDesktop}
              isActive={true}
              originalAnimState={0}
              onRelease={() => {
                this.state.eye !== 3 && this.setAnimationState('eye', 3);
              }}
              onScroll={() => {
                this.state.eye !== 2 && this.setAnimationState('eye', 2);
              }}
              animationsDefinitions={eyeStates}
            />
          )}
          <GridBox
            id={'eyeEnd'}
            translateTop={1}
            layout={contentSection1.eyeEnd}
          />
        </GridSection>

        <GridSection gridRows={sectionHeight.contentSection2} dark={darkTheme}>
          <GridBox layout={contentSection2.headline}>
            <Heading2>
              <LineBreakRenderer
                text={texts.results.contentSection2.headline}
              />
            </Heading2>
          </GridBox>
          <GridBox layout={contentSection2.text}>
            <Text.p>
              {texts.results.contentSection2.texts.span1}
              <a
                href={texts.results.contentSection2.texts.aHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                {texts.results.contentSection2.texts.aText}
              </a>
              {texts.results.contentSection2.texts.span2}
            </Text.p>
          </GridBox>
          <GridBox layout={contentSection2.image} translateTop={[0, 0, 0.5, 0]}>
            <Picture
              data={accessorData.file('resultsImage2')}
              alt={texts.results.contentSection1.image.alt}
              title={texts.results.contentSection1.image.title}
              asBackgroundImage={true}
            />
          </GridBox>
        </GridSection>
      </React.Fragment>
    );
  }
}

export default Results;

export const ResultsImagesFragment = graphql`
  fragment ResultsImagesFragment on Query {
    resultsImage2: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/03_Results/mentors-design-case-study.jpg"
      }
    ) {
      ...FileAssetFragment
    }
    pageCover: file(
      relativePath: {
        eq: "assets/caseStudies/h_da/03_Results/neugelb-in-der-page.jpg"
      }
    ) {
      ...FileAssetFragment
    }
  }
`;

const FlexBox = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
`;
